import Vue from 'vue'
import App from './App.vue'
import ElementUI from 'element-ui'
import router from './router'
import 'element-ui/lib/theme-chalk/index.css'; //引入element-ui的样式文件
Vue.use(ElementUI)//通过这个命令使所有的element-ui标签(<el-*></el-*>)可被解析
import * as echarts from 'echarts';
Vue.prototype.$echarts = echarts;
import 'echarts-liquidfill'
import axios from 'axios'
import VueAxios from 'vue-axios'

Vue.use(VueAxios, axios)

// 或者使用配置参数来初始化，详情见文档
Vue.use(echarts)
Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
